import CryptoJS from 'crypto-js'
//DES加密

export const encryptByDES = (message, key = '8008208820') => {
  let keyHex = CryptoJS.enc.Utf8.parse(key)
  let encrypted = CryptoJS.DES.encrypt(message, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString().toUpperCase()
}

//DES解密
export const decryptByDES = (ciphertext, key = '8008208820') => {
  if (!ciphertext) {
    return ciphertext
  }
  /**
   * 捕获解密错误，如有错误则展示原始值
   */
  try {
    let keyHex = CryptoJS.enc.Utf8.parse(key)
    let decrypted = CryptoJS.DES.decrypt({
      ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
    }, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (_) {
    return ciphertext
  }
}

// 解密列表中的数据
const idNo = [
  'idCardNo',
  'userIdNumber',
  'idNo',
  'idNumber',
  'documentNumber',
]
const bankCardNo = [
  'bankCardNo',
  'bankCard',
  'cardNo',
  'bankAccountNumber',
]

const phone = [
  'mobile',
  'phoneNum',
  'userMobile',
]
export function decryptList(dataList = [], attrList = [...idNo, ...bankCardNo, ...phone]) {
  return dataList.map(data => {
    attrList.forEach(attr => {
      if (data[attr]) {
        data[attr] = decryptByDES(data[attr])
      }
    })
    return data
  })
}

/**
 * TripleDES 加解密 公司管理账户配置用
 * @param {*} data
 * @param {*} key
 * @returns
 */
export const encryptData = (data, key = 'lsBnINDxtct8HZB7KCMyhWSJ') => {
  const keyHex = CryptoJS.enc.Utf8.parse(key)
  const encrypted = CryptoJS.TripleDES.encrypt(data, keyHex, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.toString()
}
export const decryptData = (data, key = 'lsBnINDxtct8HZB7KCMyhWSJ') => {
  try {
    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const decrypted = CryptoJS.TripleDES.decrypt(data, keyHex, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
    return decrypted.toString(CryptoJS.enc.Utf8)
  } catch (error) {
    console.log(error)
    return ''
  }

}
